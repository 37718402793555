<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">
        Images&nbsp;
        <span v-if="session">- {{ session.label }}</span>&nbsp;
        <va-icon :name="'fa4-edit'" style="padding-left: 1rem; cursor: pointer; color: #0000ee;" @click="editModal" />
      </va-card-title>
      <va-card-content>
        <div class="row">
          <va-input
            class="flex mb-2 md6"
            placeholder="Filter..."
            v-model="filter"
          />
        </div>
        <va-data-table
          :items="files"
          :columns="columns"
          :filter="filter"
          @filtered="setFiltered"
        >
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'file', params: { id, fileId: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filter.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a file</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>
    <va-modal
      v-model="editModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Edit a Session</h2>
      </template>
      <slot>
        <va-input
          class="mb-3"
          v-model="session.label"
          type="text"
          label="Label"
          style="margin-top: 1rem;"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="editModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="editModalConfirm">Save</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load session
      const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}`, { headers })
      this.session = session.data.session
      // Load a list of files
      const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images`, { headers })
      this.files = await Promise.all(files.data.images.map((file) => {
        file.created_at = moment(file.created_at).format('YYYY-MM-DD hh:mm a')
        this.filesObject[file.uid] = file.name
        return file
      }))
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: 'files' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      session: null,
      files: [],
      filesObject: {},
      columns: [
        {
          key: 'name',
          label: 'Files',
          headerTitle: 'Files',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filter: '',
      filtered: [],
      filteredCount: 0,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      editModalShow: false
    }
  },
  methods: {
    removeModal (id) {
      this.removeId = id
      this.removeName = this.filesObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a file
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Image set deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of file again
        const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images`, { headers })
        this.files = await Promise.all(files.data.images.map((file) => {
          file.created_at = moment(file.created_at).format('YYYY-MM-DD hh:mm a')
          return file
        }))

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: 'files' } })
      }
      this.loader.hide()
    },
    setFiltered (event) {
      this.filteredCount = event.items.length
      this.filtered = event.items
    },
    editModal () {
      this.editModalShow = true
    },
    editModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {
          label: this.session.label
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/sessions/${this.session.uid}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session edited successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load session again
        const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.session.uid}`, { headers })
        this.session = session.data.session

        this.editModalShow = false
      } catch (error) {
        this.editModalShow = false
        this.$router.push({ name: 'login', query: { redirect: 'files' } })
      }
      this.loader.hide()
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }
</style>
